@font-face {
  font-family: "aril";
  src: url("./assets/font/GEO_AI__.TTF");
}

body {
  font-family: 'aril';
}

.wrapper {
  position: relative;
  /* width: 100vw; */
  /* height: 100vh; */
  /* background-color: red; */
}

.main-content {
  width: 100%;
  height: 500px;
  /* background-color: yellow; */
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 50px;
  padding-right: 50px;
  gap: 20px;
  height: fit-content;
}

.content-item {
  background-color: white;
  padding: 20px;
  height: 200px;
  margin-top: -100px;
  z-index: 1;
}

.content-item:hover {
  background-color: white;
  padding: 20px;
  height: 200px;
  margin-top: -100px;
  z-index: 1;
  box-shadow: 0 5px 20px rgb(0 0 0 / 30%);
}

footer {
  /* background-color: #00387a; */
  /* height: 400px; */
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  padding: 50px;
}