/* #arrow-left {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 50px solid grey;
  border-bottom: 20px solid transparent;
  position: absolute;
  top: 280px;
  left: 0;
}

#arrow-right {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 50px solid grey;
  border-bottom: 20px solid transparent;
  position: absolute;
  top: 280px;
  right: 0;
} */

.slide-image {
  object-fit: cover;
  width: 100%;
  height: 500px;
}

.arrow-container-right{
  width: 40px;
  height: 40px;
  background: grey;
  position: absolute;
  top: 200px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

}
.arrow-container-left{
  width: 40px;
  height: 40px;
  background: grey;
  position: absolute;
  top: 200px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.arrow {
  display: inline-block;
  /* position: absolute; */
  width: 20px;
  height: 20px;
  background: transparent;
  text-indent: -9999px;
  border-top: 2px solid black;
  border-left: 2px solid black;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  color: transparent;
  top: 200px;
  cursor: pointer;
}

.arrow:hover {
  border-color: black;
  border-width: 6px;
}

.arrow:before {
  display: block;
  height: 200%;
  width: 200%;
  margin-left: -50%;
  margin-top: -50%;
  content: "";
  transform: rotate(45deg);
}

.arrow-prev {
  transform: rotate(-45deg);
  left: 20px;
}

.arrow-next {
  transform: rotate(135deg);
  right: 20px;
}
